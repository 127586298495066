// @owner ClientPlatform

function parseOSXVersion(ua: string) {
  const matches = ua && ua.match(/OS X (10)_([0-9]{1,2})/);

  if (!matches) {
    return null;
  }

  return {
    major: parseInt(matches[1], 10),
    minor: parseInt(matches[2], 10),
  };
}

function parseSafariVersion(ua: string) {
  const matches = ua && ua.match(/Safari\/([0-9]{3}).([0-9]{1,3}).([0-9]{1,3})/);

  if (!matches) {
    return null;
  }

  return parseInt(matches[1], 10);
}

interface PlatformUpgradeNotification {
  enabled: boolean;
  type?: 'app' | 'browser' | 'os';
}

export interface Platform {
  name: string;
  windows: boolean;
  osx: boolean;
  web: boolean;
  ios: boolean;
  nw: boolean;
  dotnet: boolean;
  linux: boolean;
  android: boolean;
  electron: boolean;
  os: 'windows' | 'osx' | 'web' | 'ios' | 'linux' | 'android' | 'electron';
  slug: string;
  upgrade_notif: PlatformUpgradeNotification;
  type?: 'app' | 'browser' | 'os';
}

export function parseUserAgent(ua: string) {
  const platform: Platform = {
    name: '',
    windows: false,
    osx: false,
    web: false,
    ios: false,
    nw: false,
    dotnet: false,
    linux: false,
    android: false,
    electron: false,
    os: 'windows',
    slug: '',
    upgrade_notif: {
      enabled: false,
    },
  };

  if (ua && ua.includes('FrontOSX')) {
    platform.name = 'osx';
    platform.osx = true;
    platform.upgrade_notif.enabled = true;
    platform.upgrade_notif.type = 'app';
  } else if (ua && ua.includes('Electron')) {
    platform.name = 'electron';
    platform.electron = true;
  } else if (ua && ua.includes('FrontWindows')) {
    platform.name = 'windows';
    platform.windows = true;
    platform.nw = true;
    platform.upgrade_notif.enabled = true;
    platform.upgrade_notif.type = 'app';
  } else if (ua && ua.includes('FrontWinNew')) {
    platform.name = 'dotnet';
    platform.dotnet = true;
    platform.upgrade_notif.enabled = true;
    platform.upgrade_notif.type = 'app';
  } else if (ua && ua.includes('FrontLinux')) {
    platform.name = 'linux';
    platform.linux = true;
    platform.nw = true;
  } else if (ua && ua.match(/ios.+com.frontapp.mobile/)) {
    platform.name = 'ios';
    platform.ios = true;
  } else if (ua && ua.match(/android.+com.frontapp.mobile/)) {
    platform.name = 'android';
    platform.android = true;
  } else {
    platform.name = 'web';
    platform.web = true;
  }

  // platform.os is useful for detecting users under Win/OSX AND the web, for instance
  if (ua && ua.includes('Windows')) {
    platform.os = 'windows';
  } else if (ua && ua.includes('Mac') && !ua.match(/iPhone|iPod|iPad/)) {
    platform.os = 'osx';
  } else if (ua && ua.match(/iPhone|iPod|iPad/)) {
    platform.os = 'ios';
  } else if (ua && ua.includes('Linux') && !ua.match(/Android/)) {
    platform.os = 'linux';
  } else if (ua && ua.match(/Android/)) {
    platform.os = 'android';
  } else {
    platform.os = 'windows';
  }

  // Internet Explorer => badly supported
  if (ua && (ua.includes('MSIE') || ua.includes('Trident/'))) {
    platform.upgrade_notif.enabled = true;
    platform.upgrade_notif.type = 'browser';
  } else if (ua && ua.includes('Safari/')) {
    const osxVersion = parseOSXVersion(ua);
    const safariVersion = parseSafariVersion(ua);

    // If we're under 10.9 then the app won't work correctly
    if (osxVersion && osxVersion.major === 10 && osxVersion.minor <= 8) {
      platform.upgrade_notif.enabled = true;
      platform.upgrade_notif.type = 'os';
    }

    // If Safari embeds a WebKit version lower than 537 then Flexbox won't be supported
    // cf: https://en.wikipedia.org/wiki/Safari_version_history#Mac
    // cf: http://caniuse.com/#feat=flexbox
    if (safariVersion && safariVersion < 537) {
      platform.upgrade_notif.enabled = true;
      platform.upgrade_notif.type = 'browser';
    }
  }

  platform.slug = platform.name + (platform.os ? `-${platform.os}` : '');

  return platform;
}

export const isFrontElectron = (ua: string) => {
  return ua && ua.match(/Electron.+\sfront-desktop/);
};

export const isFrontApplicationVersionGTE = (major: number, minor: number, patch: number) => {
  const match = navigator.userAgent.match(/ Front\/([^ ]+) /);
  if (!match) {
    return false;
  }

  const version = match[1].split('.').map((token) => Number(token));
  return (
    version[0] > major ||
    (version[0] === major && version[1] > minor) ||
    (version[0] === major && version[1] === minor && version[2] >= patch)
  );
};

export const platform = parseUserAgent(window.navigator.userAgent);
