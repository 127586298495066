// @owner ClientPlatform

import {createGlobalStyle, css} from 'styled-components';

interface SigninGlobalStyleProps {
  constrainWindow?: boolean;
}

export const SigninGlobalStyles = createGlobalStyle<SigninGlobalStyleProps>`
  // CSS Reset
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }

  body {
    line-height: 1;
    color: black;
    background: transparent;
  }

  ol, ul {
    list-style: none
  }

  a img {
    border: none;
  }

  body, button {
    background: white;
    font-family: system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  * {
    box-sizing: border-box;
  }

  button, input, textarea {
    border: 0;
    margin: 0;
    padding: 0;
    outline: 0;

    appearance: none;
  }

  button {
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    border-radius: 0;

    &:disabled {
      opacity: .6;
      cursor: default;
    }
  }

  a, a:visited, a:hover, a:active {
    color: inherit;
    text-decoration: none;
  }

  body {
    user-select: none;
    cursor: default;

    ${(p) =>
      p.constrainWindow &&
      css`
        width: 100vw;
        height: 100vh;
        overflow: hidden;
      `}
  }

  input, textarea, [contenteditable] {
    user-select: text;
    cursor: text;
  }

  button, a {
    cursor: pointer;
  }
`;
