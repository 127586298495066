// @owner ClientPlatform

import type {FC, PropsWithChildren} from 'react';
import React from 'react';
import {styled} from 'styled-components';
import frontLogoSrc from '../../../assets/front-logo.svg';

const StyledImg = styled.img`
  height: 36px;
  width: 151px;
`;

interface FrontLogoProps {
  className?: string;
}

export const FrontLogo: FC<PropsWithChildren<FrontLogoProps>> = ({className}) => {
  return <StyledImg className={className} src={frontLogoSrc} alt="Front logo" />;
};
