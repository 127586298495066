// @owner ClientPlatform

import {styled} from 'styled-components';
import {brandViolets} from '../common/theme/brandColors';

export const StyledRow = styled.div`
  margin-bottom: 20px;
  position: relative;
  text-align: center;
`;

export const StyledParagraph = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  line-height: 21px;
  width: 100%;

  &.copy {
    text-align: center;
  }
`;

export const StyledAnchor = styled.a`
  color: #001b38;
  font-size: 12px;

  &:hover {
    color: ${brandViolets.shade700};
  }
`;

export const StyledThirdPartySigninRow = styled(StyledRow)`
  margin-top: 40px;

  button:first-child {
    margin-bottom: 10px;
  }
`;
