// @owner ClientPlatform

export enum SigninPage {
  LOGIN = 'login',
  FORGOT_PASSWORD = 'forgot-password',
  PASSWORD_RESENT = 'password-resent',
  INIT_SIGNIN = 'init-signin',
  OAUTH_ONLY = 'oauth-only',
}

export type SwitchToSigninPage = (page: SigninPage) => void;
