// @owner ClientPlatform
/* eslint-disable jsx-a11y/label-has-associated-control */

import type {FC, MouseEventHandler, PropsWithChildren} from 'react';
import React from 'react';
import type {SwitchToSigninPage} from '../../helpers/pageHelpers';
import {SigninPage} from '../../helpers/pageHelpers';
import {StyledParagraph, StyledAnchor, StyledRow, StyledThirdPartySigninRow} from '../../commonSigninStyles';
import {SigninButton} from '../../components/signinButton/signinButton';

interface OauthOnlyProps {
  switchToSigninPage: SwitchToSigninPage;
  googleSignin: MouseEventHandler<HTMLElement>;
  officeSignin: MouseEventHandler<HTMLElement>;
}

export const OauthOnly: FC<PropsWithChildren<OauthOnlyProps>> = ({switchToSigninPage, googleSignin, officeSignin}) => {
  return (
    <form name="oauthOnlySignInForm">
      <StyledRow>
        <StyledParagraph>Your company requires you to login with Google or Office 365</StyledParagraph>
      </StyledRow>

      <StyledThirdPartySigninRow>
        <SigninButton type="button" onClick={googleSignin}>
          Sign in with Google
        </SigninButton>
        <SigninButton type="button" onClick={officeSignin}>
          Sign in with Office 365
        </SigninButton>
      </StyledThirdPartySigninRow>

      <StyledRow>
        <StyledParagraph>
          <StyledAnchor
            href="#"
            onClick={(e) => {
              e.preventDefault();
              switchToSigninPage(SigninPage.LOGIN);
            }}
          >
            Back
          </StyledAnchor>
        </StyledParagraph>
      </StyledRow>
    </form>
  );
};
