// @owner ClientPlatform
/* eslint-disable jsx-a11y/label-has-associated-control */

import type {FC, PropsWithChildren} from 'react';
import React from 'react';
import type {SwitchToSigninPage} from '../../helpers/pageHelpers';
import {SigninPage} from '../../helpers/pageHelpers';
import {StyledParagraph, StyledAnchor, StyledRow} from '../../commonSigninStyles';
import {styled} from 'styled-components';
import {SigninButton} from '../../components/signinButton/signinButton';

const StyledAnchorLargeText = styled(StyledAnchor)`
  font-size: 14px;
`;

interface PasswordResentProps {
  forgotEmail: string;
  switchToSigninPage: SwitchToSigninPage;
}

export const PasswordResent: FC<PropsWithChildren<PasswordResentProps>> = ({forgotEmail, switchToSigninPage}) => {
  return (
    <form name="forgotPasswordform">
      <StyledRow>
        <StyledParagraph>
          If the email <StyledAnchorLargeText href={`mailto:${forgotEmail}`}>{forgotEmail}</StyledAnchorLargeText>{' '}
          exists, you will receive a password recovery link within a few minutes.
        </StyledParagraph>
      </StyledRow>
      <StyledRow>
        <SigninButton
          type="button"
          onClick={(e) => {
            e.preventDefault();
            switchToSigninPage(SigninPage.LOGIN);
          }}
        >
          Back to login
        </SigninButton>
      </StyledRow>
    </form>
  );
};
