// @owner ClientPlatform
/* eslint-disable jsx-a11y/label-has-associated-control */

import type {FC, PropsWithChildren} from 'react';
import React, {useState} from 'react';
import {SigninPage, type SwitchToSigninPage} from '../../helpers/pageHelpers';
import {StyledParagraph, StyledAnchor, StyledRow} from '../../commonSigninStyles';
import {SigninInput} from '../../components/signinInput/signinInput';
import {SigninButton} from '../../components/signinButton/signinButton';
import {requester} from '../../../helpers/requestHelpers';

interface ForgotPasswordProps {
  switchToSigninPage: SwitchToSigninPage;
  setForgotEmail: (email: string) => void;
}

interface ForgotPasswordWindow extends Window {
  analytics?: {
    identify: (email: string) => void;
    track: (eventName: string) => void;
  };
}

function findForgotPasswordWindow() {
  return window as ForgotPasswordWindow;
}

export const ForgotPassword: FC<PropsWithChildren<ForgotPasswordProps>> = ({switchToSigninPage, setForgotEmail}) => {
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [isSending, setIsSending] = useState(false);

  async function requestNewPassword() {
    setIsSending(true);
    setError('');

    setForgotEmail(email);

    try {
      await requester.post(`/api/1/request_new_password/${email}`);

      const window = findForgotPasswordWindow();
      if (window.analytics) {
        window.analytics.identify(email);
        window.analytics.track('clicked_reset_password_app');
      }

      switchToSigninPage(SigninPage.PASSWORD_RESENT);
    } catch (e) {
      if (e.response.status === 404) {
        setError('reset_password_not_found');
      }
    } finally {
      setIsSending(false);
    }
  }

  return (
    <form
      name="forgotPasswordform"
      onSubmit={(e) => {
        e.preventDefault();
        requestNewPassword();
      }}
    >
      <StyledRow>
        <StyledParagraph>
          We can help you reset your password using the email address linked to your account.
        </StyledParagraph>
      </StyledRow>
      <StyledRow>
        <SigninInput
          value={email}
          id="email-login"
          label="Email"
          type="email"
          name="email"
          placeholder="Your email"
          required
          autoFocus
          onChange={setEmail}
          error={error === 'reset_password_not_found' ? 'Email not found' : undefined}
        />
      </StyledRow>
      <StyledRow>
        <SigninButton type="submit" disabled={isSending}>
          Reset my password
        </SigninButton>
      </StyledRow>
      <StyledRow>
        <StyledParagraph>
          <StyledAnchor
            href="#"
            onClick={(e) => {
              e.preventDefault();
              switchToSigninPage(SigninPage.LOGIN);
            }}
          >
            Cancel
          </StyledAnchor>
        </StyledParagraph>
      </StyledRow>
    </form>
  );
};
