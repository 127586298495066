// @owner ClientPlatform

import type {FC, PropsWithChildren} from 'react';
import React from 'react';
import {classNames} from '../../../helpers/classNameHelpers';
import {styled} from 'styled-components';
import warningFilledRedSrc from '../../../assets/warningFilled-red.svg';

const StyledWrapper = styled.div<{$variant: SigninInputVariant}>`
  position: relative;
  width: 270px;
  margin: 0 auto;

  ${(p) => p.$variant === SigninInputVariant.INLINE && 'display: flex'};
`;

const StyledField = styled.div`
  background: #f3f2f0;
  border: 2px solid #f3f2f0;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 500;
  text-align: left;
  position: relative;
  overflow: hidden;
  transition-property: background, border;
  transition-duration: 0.3s;
  transition-timing-function: ease;

  &:focus-within:hover,
  &:focus-within {
    background: #ffffff;
    border: 2px solid #0090da;
  }

  &:hover {
    border: 2px solid #000;
  }

  &.invalid {
    background: #ffd6d6;
    border: 2px solid #ffd6d6;

    &:focus-within {
      background: #fff;
      border: 2px solid #ff0c0c;
    }

    .invalid-icon {
      position: absolute;
      top: 14px;
      right: 14px;
      width: 16px;
      height: 16px;
      background-image: url(${warningFilledRedSrc});
    }

    input {
      color: rgba(0, 0, 0, 0.4);
      padding-right: 40px;
    }
  }
`;

const StyledFieldSpan = styled.span`
  display: block;
  overflow: hidden;
  position: relative;
`;

// Labels are present, but visually hidden for accessibility
// https://www.w3.org/WAI/tutorials/forms/labels/#hiding-the-label-element
const StyledFieldLabel = styled.label`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

const StyledFieldInput = styled.input<{$variant: SigninInputVariant}>`
  background: transparent;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  padding: 9px 16px;
  width: 100%;
  position: relative;
  transition-property: padding, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  ${(p) => p.$variant === SigninInputVariant.INLINE && 'text-align: right'};

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const StyledErrorTooltip = styled.span`
  position: absolute;
  height: 24px;
  padding: 7px 12px;
  z-index: 9999;
  opacity: 0;
  transform: translate(50px, 0);
  transition: all 0.3s ease;
  margin-top: -10px;
  right: 0;

  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  color: #4c5c6e;
  font-size: 12px;
  font-style: italic;
  line-height: 0.8;

  &.visible {
    display: inline;
    opacity: 1;
    transform: translate(0, 0);
  }

  @media (min-width: 640px) {
    &.left {
      right: 310px;
    }
  }
`;

const StyledAppendToField = styled.span`
  line-height: 47px;
  font-weight: 500;
  font-size: 16px;
`;

export enum SigninInputVariant {
  DEFAULT = 'default',
  INLINE = 'inline',
}

interface SigninInputProps {
  value: string;
  id: string;
  label: string;
  type: string;
  name: string;
  placeholder: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  error?: string;
  autoFocus?: boolean;
  required?: boolean;
  appendText?: string;
  variant?: SigninInputVariant;
}

export const SigninInput: FC<PropsWithChildren<SigninInputProps>> = ({
  error,
  value,
  id,
  label,
  type,
  name,
  placeholder,
  onChange,
  onBlur,
  autoFocus,
  required,
  appendText,
  variant = SigninInputVariant.DEFAULT,
}) => {
  return (
    <StyledWrapper $variant={variant}>
      <StyledField
        className={classNames({
          field: true,
          invalid: Boolean(error),
          'not-empty': Boolean(value),
        })}
      >
        {maybeRenderLabel(id, label)}
        <StyledFieldSpan>
          <StyledFieldInput
            id={id}
            $variant={variant}
            type={type}
            name={name}
            placeholder={placeholder}
            required={required}
            autoFocus={autoFocus}
            autoComplete="off"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlur}
          />
        </StyledFieldSpan>
        <StyledFieldSpan className="invalid-icon" />
      </StyledField>
      {maybeRenderAppendText(appendText)}
      <StyledErrorTooltip
        className={classNames({
          'error-tooltip': true,
          visible: Boolean(error),
        })}
      >
        {error}
      </StyledErrorTooltip>
    </StyledWrapper>
  );
};

function maybeRenderLabel(id: string, label?: string) {
  if (!label) {
    return null;
  }

  return <StyledFieldLabel htmlFor={id}>{label}</StyledFieldLabel>;
}

function maybeRenderAppendText(appendText?: string) {
  if (!appendText) {
    return null;
  }

  return <StyledAppendToField>{appendText}</StyledAppendToField>;
}
