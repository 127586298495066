// @owner ClientPlatform

import React, {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {Signin} from './signin';

const root = createRoot(document.getElementById('root')!);
root.render(
  <StrictMode>
    <Signin />
  </StrictMode>
);
