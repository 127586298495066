// @owner ClientPlatform

export interface BannerOptions {
  dismissable: boolean;
  type: string;
  critical: boolean;
  message: string;
  caption: string;
  link: string;
}

export interface PlatformInfo {
  osx?: boolean;
  upgrade_notif: {
    type: 'browser' | 'app' | 'os';
    enabled: boolean;
  };
}

export function findBannerOptionsForPlatform(platformObject: PlatformInfo): BannerOptions | null {
  if (!platformObject.upgrade_notif.enabled) {
    return null;
  }

  const bannerOpts = {dismissable: true, type: 'warning', critical: true};

  switch (platformObject.upgrade_notif.type) {
    case 'browser':
      return {
        ...bannerOpts,
        message: 'It looks like your browser is too old to run Front properly.',
        caption: 'Update your browser',
        link: 'https://browser-update.org/update.html?force_outdated=true',
      };

    case 'app':
      return {
        ...bannerOpts,
        message: 'Your version of the desktop app is outdated, Front might not run properly.',
        caption: 'Update your application',
        link: 'https://front.com/download',
      };

    case 'os':
      if (!platformObject.osx) {
        return null;
      }

      return {
        ...bannerOpts,
        message: 'Your version of macOS is outdated, Front might not run properly.',
        caption: 'Upgrade',
        link: 'https://www.apple.com/osx/whats-new/',
      };

    default:
      return null;
  }
}
