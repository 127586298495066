// @owner ClientPlatform

export function findLocationHref() {
  return window.location.href;
}

export function setLocationHref(href: string) {
  window.location.href = href;
}

/**
 * Navigate to a new location in the same context.
 * For installed web apps, this is useful to open the new location in the app context,
 * instead of opening a new tab in the browser.
 * @param  {string} href - The URL to navigate to.
 */
export function navigateInContext(href: string) {
  if ('standalone' in navigator && Boolean(navigator.standalone)) {
    window.open(href, '_self');
  } else {
    setLocationHref(href);
  }
}
