// @owner ClientPlatform
/* eslint-disable no-underscore-dangle */

import {isFrontApplicationVersionGTE, platform} from './userAgent';
import type {PlatformInfo} from '../signin/helpers/bannerHelpers';
import {findBannerOptionsForPlatform} from '../signin/helpers/bannerHelpers';
import {requester} from './requestHelpers';

// @ts-expect-error - windowBridge is defined on window by Electron
const electronBridge = (platform.electron && window.parent && window.parent.windowBridge) || window.windowBridge;

interface LegacyBridgeMethods {
  userDidSignIn_(baseUrl?: string): void;
  userDidSignIn(): void;
  openPrintPanel(): void;
}

interface BridgeMethods {
  userSignedIn(baseUrl?: string, shouldReload?: boolean): Promise<void>;
  showPopup(): void;
  eval(script: string): void;
}

interface LocalBridgeMethods {
  appLoaded?(): void;
}

export interface FrontUIWindow extends Window {
  __version?: number;

  eval?(script: string): void;
  osx?: LegacyBridgeMethods;
  dotnet?: BridgeMethods;
  windowBridge?: BridgeMethods;
  localBridge?: LocalBridgeMethods;

  parent: FrontUIWindow;
}

export function findSignupWindow() {
  return window as FrontUIWindow;
}

export function signalAppLoaded() {
  const window = findSignupWindow();
  window.parent?.localBridge?.appLoaded?.();
}

export async function fetchBannerOptions() {
  const window = findSignupWindow();
  const response = await requester.get<{platform: PlatformInfo}>(`/boot/signin?v=${window.__version}`);
  if (response.data.platform) {
    return findBannerOptionsForPlatform(response.data.platform);
  }

  return null;
}

export function findBaseUrl() {
  return `${window.location.protocol}//${window.location.host}`;
}

export function userDidSignIn(redirectUrl: string, baseUrl: string) {
  const window = findSignupWindow();

  // Legacy macOS app: different behavior.
  if (platform.osx) {
    const supportSSO = window.osx && window.osx.userDidSignIn_;
    if (supportSSO) {
      window.osx?.userDidSignIn_(baseUrl);
    } else {
      window.osx?.userDidSignIn();
    }

    return window.close();
  }

  // Otherwise, try and find the native platform bridge.
  const dotnetBridge =
    platform.dotnet &&
    ((window.opener && window.opener.dotnet && window.opener.dotnet.userSignedIn && window.opener.dotnet) ||
      // @ts-expect-error double check whether window.dotnet.userSignedIn is always defined.
      (window.dotnet && window.dotnet.userSignedIn && window.dotnet));
  const nativeBridge = electronBridge || dotnetBridge;

  // If this is not a native platform, just redirect.
  if (!nativeBridge) {
    return redirectToUrl(redirectUrl);
  }

  // Handle electron.
  if (platform.electron) {
    return handleElectronPlatform(nativeBridge, baseUrl, redirectUrl);
  }

  // if we have a bridge but not in electron, this is a legacy app still.
  if (nativeBridge.userSignedIn) {
    return handleLegacyApps(nativeBridge);
  }

  // Otherwise, redirect and close the window if needed.
  // We shouldn't end up in that situation though.
  return redirectAndClosePopup(redirectUrl);
}

export function pathToRedirectTo() {
  const redirectUrlParam = new URLSearchParams(window.location.search).get('redirect_url');

  if (redirectUrlParam && new URL(redirectUrlParam, window.location.href).origin === window.location.origin) {
    return redirectUrlParam;
  }

  return '/';
}

interface Dimensions {
  height: number;
  width: number;
}

export function openSigninPopup(url: string, title: string, size: Dimensions) {
  const popupType = 'web-popup';
  const window = findSignupWindow();
  let redirectUrl = url;

  // Old platform but still have some customers somehow?
  if (platform.dotnet) {
    redirectUrl += url.indexOf('?') < 0 ? '?' : '&';
    redirectUrl += `popup_type=${encodeURIComponent(popupType)}&popup_title=${encodeURIComponent(title)}`;
  }

  const windowArgs = `menubar=no,title,resizable=yes,scrollbars=yes,location=no,width=${size.width},height=${size.height}`;
  if (platform.electron) {
    if (electronBridge && isFrontApplicationVersionGTE(3, 21, 5)) {
      return electronBridge.openAuthPopup(redirectUrl);
    }

    const w = window.open(redirectUrl, '_blank', windowArgs) as FrontUIWindow;
    if (w && w.windowBridge) {
      return w.windowBridge.showPopup();
    }
    if (w && w.eval) {
      return w.eval('windowBridge.showPopup();');
    }
  }

  return window.open(redirectUrl, title, windowArgs);
}

function handleElectronPlatform(bridge: BridgeMethods, baseUrl?: string, redirectUrl?: string) {
  return bridge
    .userSignedIn(baseUrl, true)
    .then(() => {
      // Newest versions of front desktop will do the redirection themselves.
      // But not older versions (pre 3.0.x) so we need the following line for the transition.
      redirectAndClosePopup(redirectUrl);
    })
    .catch(() => redirectAndClosePopup(redirectUrl));
}

async function handleLegacyApps(bridge: BridgeMethods, baseUrl?: string, redirectUrl?: string) {
  await bridge.userSignedIn(baseUrl);
  return redirectAndClosePopup(redirectUrl);
}

function redirectAndClosePopup(redirectUrl?: string) {
  redirectToUrl(redirectUrl);

  if (window.opener) {
    window.close();
  }
}

function redirectToUrl(url?: string) {
  if (platform.electron && window.opener && window.opener.eval) {
    return redirectElectronToUrl(url);
  }

  return redirectWebAppToUrl(url);
}

function getAppWindow() {
  // If there is no opener, the main app is this window
  if (!window.opener) {
    return window;
  }

  try {
    // If the popup and the opener have the same origin, the opener is the main app.
    const sameOrigin = window.opener.location.host === window.location.host;

    return sameOrigin ? window.opener : window;
  } catch (error) {
    // If an error is thrown in the try, it means that we are violating cross-origin policy.
    // In that case, the main app is this window that has been open by a 3rd party
    return window;
  }
}

function redirectWebAppToUrl(url?: string) {
  const appWindow = getAppWindow();

  // Notify the app that we're loading.
  appWindow?.parent?.localBridge?.appLoading?.();
  appWindow.location.href = url;
  return url;
}

// Electron opens the signup app in a popup and Electron's opener does not expose much more than eval
function redirectElectronToUrl(url?: string) {
  // Notify the app that we're loading.
  window.opener.eval(
    'window.parent && window.parent.localBridge && window.parent.localBridge.appLoading && window.parent.localBridge.appLoading();'
  );

  return window.opener.eval(`location.href = '${url}';`);
}

export function isSsoSupported() {
  const window = findSignupWindow();
  return window.osx ? Boolean(window.osx.userDidSignIn_) : true;
}

export function isSignupSupported() {
  return !navigator.userAgent.includes('front-desktop mas');
}

export function returnValidRedirectUrl(url: string) {
  try {
    if (url && new URL(url, window.location.href).origin === window.location.origin) {
      return url;
    }

    return '/';
  } catch (err) {
    return '/';
  }
}
