// @owner ClientPlatform
/* eslint-disable jsx-a11y/label-has-associated-control */

import type {MouseEvent} from 'react';
import React, {useEffect, useState} from 'react';
import {Login} from './pages/login/login';
import {ForgotPassword} from './pages/forgotPassword/forgotPassword';
import {InitSignin} from './pages/initSignin/initSignin';
import {OauthOnly} from './pages/oauthOnly/oauthOnly';
import {PasswordResent} from './pages/passwordResent/passwordResent';
import type {SwitchToSigninPage} from './helpers/pageHelpers';
import {SigninPage} from './helpers/pageHelpers';
import Cookies from 'js-cookie';
import {pathToRedirectTo, openSigninPopup, signalAppLoaded, fetchBannerOptions} from '../helpers/signupHelpers';
import {platform} from '../helpers/userAgent';
import {styled} from 'styled-components';
import {SigninGlobalStyles} from './signinGlobalStyles';
import type {BannerOptions} from './helpers/bannerHelpers';
import {FrontLogo} from '../openInFront/components/frontLogo/frontLogo';

export const StyledWrapper = styled.div`
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
`;

export const StyledPageWrapper = styled.div`
  text-align: center;
  background: white;
  height: 100%;
  width: 270px;
  margin: 0 auto;

  &.login,
  &.password-resent,
  &.forgot-password {
    width: 289px;
  }
`;

export const StyledHeader = styled(FrontLogo)`
  margin: 40px auto 44px;
`;

const StyledSignupBanner = styled.div`
  position: relative;
  top: 0;
  left: 0;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  background-color: #d5246b;
  background-image: linear-gradient(-135deg, #d5246b 0%, #7c1d73 100%);

  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
`;

const StyledSignupBannerAnchor = styled.a`
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
  position: relative;
  z-index: 999999;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }
`;

enum ThirdPartySigninProvider {
  GOOGLE = 'google',
  OFFICE = 'office365',
}

export const Signin = () => {
  const [page, setPage] = useState(SigninPage.LOGIN);
  const [forgotEmail, setForgotEmail] = useState('');
  const [bannerOptions, setBannerOptions] = useState<BannerOptions | null>(null);

  useEffect(() => {
    signalAppLoaded();
    fetchBannerOptions().then(setBannerOptions);
  }, []);

  return (
    <StyledWrapper>
      <SigninGlobalStyles />
      {maybeRenderBanner(bannerOptions)}
      <StyledPageWrapper id="container" className={page}>
        <StyledHeader />
        {renderPage(page, setPage, forgotEmail, setForgotEmail)}
      </StyledPageWrapper>
    </StyledWrapper>
  );
};

function maybeRenderBanner(options: BannerOptions | null) {
  if (!options) {
    return null;
  }

  const {message, caption, link} = options;
  return (
    <StyledSignupBanner>
      {message}{' '}
      <StyledSignupBannerAnchor href={link} target="_blank" rel="noreferrer">
        {caption}
      </StyledSignupBannerAnchor>
    </StyledSignupBanner>
  );
}

function renderPage(
  page: SigninPage,
  setPage: SwitchToSigninPage,
  forgotEmail: string,
  setForgotEmail: (email: string) => void
) {
  switch (page) {
    case SigninPage.LOGIN:
      return <Login switchToSigninPage={setPage} googleSignin={googleSignin} officeSignin={officeSignin} />;
    case SigninPage.FORGOT_PASSWORD:
      return <ForgotPassword switchToSigninPage={setPage} setForgotEmail={setForgotEmail} />;
    case SigninPage.PASSWORD_RESENT:
      return <PasswordResent switchToSigninPage={setPage} forgotEmail={forgotEmail} />;
    case SigninPage.INIT_SIGNIN:
      return <InitSignin switchToSigninPage={setPage} />;
    case SigninPage.OAUTH_ONLY:
      return <OauthOnly switchToSigninPage={setPage} googleSignin={googleSignin} officeSignin={officeSignin} />;
    default:
      return null;
  }
}

function thirdPartySignin(provider: ThirdPartySigninProvider) {
  const loginUrl = `${window.location.protocol}//${window.location.host}/oauth/${provider}-signin`;
  const customRedirectUrl = pathToRedirectTo();
  if (customRedirectUrl && customRedirectUrl !== '/') {
    Cookies.set('front.redirect_to', customRedirectUrl);
  }

  if (platform.web) {
    window.location.href = loginUrl;
  } else {
    openSigninPopup(loginUrl, 'Sign in to Front', {height: 800, width: 650});
  }
}

function googleSignin(event: MouseEvent) {
  event.preventDefault();
  thirdPartySignin(ThirdPartySigninProvider.GOOGLE);
}

function officeSignin(event: MouseEvent) {
  event.preventDefault();
  thirdPartySignin(ThirdPartySigninProvider.OFFICE);
}
